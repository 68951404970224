/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

@font-face
{
  font-family: 'Audiowide';
  src: local('Audiowide'), url(../fonts/Audiowide-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MightySans';
  src: local('MightySans'), url(../fonts/MightySans-Display.ttf) format('truetype');
}

.sidebar .logo a.logo-normal
{
  text-transform: none;
  font-family: 'MightySans';
  font-size: 2rem;
  color: #F99DC7;
}
.navbar .navbar-brand
{
  font-family: 'MightySans';
}

.navbar a:not(.btn):not(.dropdown-item)
{
  color: #114647;
}

.navbar
{
  background-color: #ffffff ;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}

.navbar-production
{
  background-color: #FF7A6C !important;
}

.navbar-qa
{
  background-color: #5CF79B !important;
}


.sidebar .sidebar-wrapper
{
  padding-bottom: 10px;
}

.sidebar[data-active-color="info"] .nav li.active > a
{
  color: #F99DC7;
}

.sidebar[data-active-color="info"] .nav li.active > a i
{
  color: #F99DC7;
}

.sidebar .nav li > a, .off-canvas-sidebar .nav li > a {
  font-weight: 600;
  margin: 1px 15px 0;
  line-height: 20px;
}

.jsoneditor
{
  height: 20em;
  border: thin solid #51bcda;
}

.jsoneditor-menu {
  background-color: #51bcda;
  border-bottom: 1px solid #51bcda;
}

.react-toggle--checked .react-toggle-thumb {
  border-color: #65DB94;
}
.react-toggle--checked .react-toggle-track {
  background-color: #65DB94;
}

.input-group>.input-group-prepend {
  flex: 0 0 20%;
}
.input-group .input-group-text {
  width: 100%;
}


code {
  /* background: #eee; */
  padding: .1rem;
  font-family: 'Menlo';
  font-size: 13px;
  color: #ff00aa;
}

pre {
  font-size: 100%;
}

input {
  padding: .5rem;
  font-size: 16px;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error, select.error {
  border-color: red;
}

textarea.error {
  border-color: red;
}

.form-select.error {
  border-color: red;
}

.text-editor input.error
{
  border-color: red;
}
.input-group-prepend.error, .input-group-text.error
{
  border-color: red;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: .5rem;
}

.input-feedback {
  color: red;
  margin-top: .25rem;
}


button:disabled {
  opacity: .5;
  cursor: not-allowed !important;
}

tr.tr-link
{
  cursor: pointer !important;
}

.table-hover > tbody > tr:hover
{
  background-color: #d8ddec;
}

.table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.re-message {
  border-top: 0px !important;
  padding-left: 2em !important;
  font-size: x-small;
}

.left-vertical-tabs {
  width: 170px;
  float: left;
}
.nav-stacked {
  border-right: 1px solid #F1EAE0;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 0;
}

.nav-stacked > li > a {
  color: #A49E93;
  padding: 7px 25px;
  text-align: right;
}

.nav-stacked>li+li {
  margin-top: 5px;
  margin-left: 0;
}

.nav-stacked .nav li > a, .off-canvas-sidebar .nav li > a {
  margin: 10px 15px 0;
  color:
  #FFFFFF;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  padding: 10px 8px;
  line-height: 30px;
  opacity: .7;
}

/* button + button {
  margin-left: .5rem;
} */

.td-actions
{
  min-width: 100px;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}

.red
{
  color: #ff0000;
}

.green
{
  color: #19ab27;
}

.dollar-sign
{
  color: #114647;
}

.trash-sign
{
  color: #FF7A6C;
  font-size: 1.5em;
}


.blue
{
  color: #1F77D0;
}

.progress-animation
{
  margin-top: 10px;
  margin-right: 10px;
}

.form-control {
  color: #114647;
  font-size: 12px;
}

.form-control:not(textarea) {
  height: 30px;
}

.form-control[readonly], .form-control {
  cursor: auto; 
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 6px 2px;
}
.btn-sm {
  padding: 6px 10px;
}
.mb-3, .my-3 {
  margin-bottom: 3px !important;
}

.form-group .input-group-prepend .input-group-text, .form-group .input-group-append .input-group-text, .input-group .input-group-prepend .input-group-text, .input-group .input-group-append .input-group-text {
  padding: 1px 0 1px 1px;
}

.table-responsive {
  overflow: visible;
  font-size: smaller;
}

.table-full-width {
  overflow: visible;
  font-size: smaller;
}

.text-editor input {
  font-family: "Courier New", Courier, monospace;
  text-align: center;
  font-size: 290%;
  width: 100%;
  background-color: #51bcda;
  border: 1px solid #cccccc;
  border-radius: 0.6em;
  color: #ffffff;
  padding-top: 0.075rem;
}

.human-readable {
  font-size: 150%;
  min-height: 2.2em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0.2em;
  text-align: center;
}

.part-explanation {
  text-align: center;
  font-size: 100%;
  color: #888;
  height: 10em;
  padding: 0.1em 0em;
  font-family: "Courier New", Courier, monospace;
}

.part-explanation div {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.3em 0 0.3em;
}

.part-explanation table {
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  width: 100%;
  max-width: 400px;
}

.part-explanation table th {
  width: 7em;
  text-align: right;
  padding: 0.2em 1em 0.2em 0;
}


.nav-pills .nav-item .nav-link {
  border: 1px solid #66615b;
  border-radius: 0;
  color: #66615b;
  font-weight: 700;
  margin-left: -1px;
  padding: 10px 25px;
}

.nav-pills-primary .nav-item .nav-link.active {
  border: 1px solid #65DB94;
  background: #65DB94;
  color: #fff;
}
.nav-pills .nav-item:first-child .nav-link {
  border-radius: 30px 0 0 30px!important;
  margin: 0;
}
.nav-pills-primary .nav-item.show .nav-link, .nav-pills-primary .nav-link.active {
  background-color: #65DB94!important;
}

.nav-pills-primary .nav-item .nav-link {
  border: 1px solid #65DB94;
  color: #65DB94;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-pills .nav-item:last-child .nav-link {
  border-radius: 0 30px 30px 0!important;
}

.unread {
  /* background-color: ghostwhite; */
  font-weight: 800;
}

.deleted {
  /* background-color: ghostwhite; */
  color: #cc2127;
}

.archived {
  /* background-color: ghostwhite; */
  color: #2E28A6;
}

.cheater {
  color: #FF1800;
}

h3, .h3 {
  font-size: 2em;
  margin-bottom: 0px;
  line-height: 1.4em;
}

textarea.meta-data {
  height: 150px;
  max-height: 150px;
  line-height: 1;
}

textarea.support-message {
  height: 180px;
  max-height: 180px;
  font-size: 0.9em;
}
.sidebar[data-color="black"]::after, .off-canvas-sidebar[data-color="black"]::after {
  background: linear-gradient(#114647,#114647 100%);
  z-index: 3;
}

.sidebar .nav li > a, .off-canvas-sidebar .nav li > a {
  opacity: .8;
  font-weight: 800;
}

.sidebar .nav i, .off-canvas-sidebar .nav i {
  
  color: rgba(255, 255, 255, 0.8);
}

.card + .card {
  margin-left: .5rem;
}

.card {
  background-color: #f4f7f8;
}


.card-stats
{
  min-width: 300px;
}

.icon-button
{
  background: transparent;
  font-weight: 600;
  font-size: 0.8571em;
  line-height: 1.35em;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: all 150ms linear;
}
.icon-button :hover
{
  background: transparent;
}
.icon-trivial
{
  color: #85E8FF;
}

.icon-normal
{
  color: #F99DC7;
}

.icon-critical
{
  color: #FF7A6C;
}

.page-item.active .page-link {
  background-color: #65DB94;
  border-color: #65DB94;
  color: #114647;
}
.page-link {
  color: #5CF79B;
  font-weight: bold;
  color: #114647;
}

.nav-stacked > li > a.active {
  color: #F99DC7;
}

.nav-stacked > li > a {
  color: #807a6f;
  padding: 7px 25px 7px 0px;
  text-align: left;
}

.react-toast-notifications__container {
  z-index: 1100 !important;
  top: 64px !important;
}

.react-toast-notifications__toast {
  width: 400px !important;
}

.react-toast-notifications__toast__content {
  font-size: 13px !important;
}

div.support-message {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}


.main-panel {
  background-color: #ffffff;
}

.text-primary, a.text-primary:focus, a.text-primary:hover {
  color: #F99DC7 !important;
}

.btn-primary {
  background-color: #5CF79B;
  color: #114647;
}

.btn-primary:active {
  background-color: #5CF79B !important;
  color: #114647 !important;
}

.btn-primary:focus {
  background-color: #5CF79B !important;
  color: #114647 !important;
}

.btn-primary:disabled {
  background-color: #7FC79C !important;
  color: #114647 !important;
}

.btn-primary:hover {
  background-color: #65DB94 !important;
  color: #114647 !important;
}

.btn-danger {
  background-color: #FF7A6C;
  color: #ffffff;
}

.btn-danger:active {
  background-color: #FF7A6C !important;
  color: #ffffff !important;
}

.btn-danger:focus {
  background-color: #FF7A6C !important;
  color: #ffffff !important;
}

/*.btn-primary:disabled {*/
/*  background-color: #7FC79C !important;*/
/*  color: #114647 !important;*/
/*}*/

.btn-danger:hover {
  background-color: #D94E3F !important;
  color: #ffffff !important;
}

.inline-button {
  padding: 0px 10px;
}

.badge-secondary {
  background-color: #114647;
}

.react-toggle-track {
  width: 46px;
  height: 20px;
  padding: 0;
  border-radius: 10px;
  background-color: #114647;
  transition: all 0.2s ease;
}

.react-toggle-thumb {
  width: 18px;
  height: 18px;
}

table.modal-title {
  width: 100%;
}