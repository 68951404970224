

.toggle-wrapper {
  /* margin: 0.5em auto; */
  padding: 0px 0px 0px 0px !important;
}

.toggle-wrapper label {
  padding: 0.3em;
  /* font-size: 17px; */
  display: block;
}

.toggleContainer {
  width: 100%;
  position: relative;
  /* background: #ffffff; */
  /* border: 1px solid #eeeeee; */
}

.toggleContainer .toggleOption {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  /* color: #807b77; */
  font-size: 1em;
}

.toggleContainer .toggleOption.selected {
  color: #114647;
  cursor: initial;
}

.toggleContainer .toggle {
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  background: #65DB94;
  border-radius: 4px;
}
