.swagger-ui .btn.authorize span {
    float: left;
    padding: 4px 10px 0 0;
}

.swagger-ui .opblock-tag 
{
    font-size: 18px;
}

.swagger-ui .scheme-container {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 0;
}

.swagger-ui .auth-wrapper {
    justify-content: flex-start;
}

button {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.swagger-ui .opblock-tag {
    
    padding: 0px 20px 0px 0px;
}
.swagger-ui .opblock {
    margin: 0 0 5px;
}

.swagger-ui .opblock .opblock-summary {
  
    padding: 2px;
}

.swagger-ui .opblock .opblock-summary-method {
    font-size: 12px;
    font-weight: 600;
}

.swagger-ui .opblock .opblock-summary-path
{
    font-size: 14px;
}

.swagger-ui .markdown p, .swagger-ui .markdown pre, .swagger-ui .renderedMarkdown p, .swagger-ui .renderedMarkdown pre {
    margin: 5px 15px;
}

.swagger-ui table tbody tr td:first-of-type {
    padding: 13px 0px;
    font-size: 14px;
}

.swagger-ui textarea.curl {
    min-height: 20px;
    background: #606060;
}

.swagger-ui .opblock-body pre.microlight {
    background: #606060;
}

.swagger-ui .btn-group {
    padding: 0 15px 10px;
}

.swagger-ui .execute-wrapper {
    padding: 0 15px 10px;
    text-align: left;
}

.swagger-ui .execute-wrapper .btn {
    padding:5px 23px;
}

.swagger-ui, .swagger-ui .info p, .swagger-ui .opblock-tag, .swagger-ui .opblock-tag small, .swagger-ui .opblock .opblock-section-header h4,
.swagger-ui table thead tr td,.swagger-ui .tab li,.swagger-ui .parameter__name, .swagger-ui table thead tr th, .swagger-ui .response-col_status,
.swagger-ui .info .title {
    height: 100%;
}

.swagger-ui .response-col_status {
    font-size: 12px;
}
.swagger-ui a.nostyle {
    min-width: 200px;
}

.swagger-ui .opblock-tag {
    align-items: baseline;
}

.swagger-ui .model-title {
    font-size: 16px;
}

.swagger-ui .model {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.swagger-ui .markdown code, .swagger-ui .renderedMarkdown code {
    font-size: 14px;
    padding: 5px 7px;
    border-radius: 4px;
    background: rgba(0,0,0,.05);
    font-family: monospace;
    font-weight: 500;
    color: #000000;
}

.swagger-ui
{
    height: 100%;
}
.swagger-ui .wrapper
{
    height: 100%;
}